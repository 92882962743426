import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Layout from "../components/layout";
import useBlogStyles from "../components/blog/styles";
import SEO from "../components/seo";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  date: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

const BlogPost = ({ data: { post } }) => {
  const blogClasses = useBlogStyles();
  const classes = useStyles();
  const image = getImage(post.frontmatter.hero_image);

  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.excerpt} />

      <Container maxWidth="md">
        <Paper>
          {image && (
            <GatsbyImage
              image={image}
              style={{ height: "400px" }}
              alt={post.frontmatter.hero_image_alt}
            />
          )}
          {post.frontmatter.hero_image_credit_text && (
            <Box p={0.5}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
              >
                Image by{" "}
                <Link href={post.frontmatter.hero_image_credit_link}>
                  {post.frontmatter.hero_image_credit_text}
                </Link>
              </Typography>
            </Box>
          )}

          <Box p={3}>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              className={blogClasses.title}
            >
              {post.frontmatter.title}
            </Typography>
            <Divider className={classes.divider} />
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.date}
            >
              {post.frontmatter.author}, {post.frontmatter.date}
            </Typography>

            <MDXRenderer>{post.body}</MDXRenderer>
          </Box>
        </Paper>
      </Container>
    </Layout>
  );
};
export default BlogPost;

export const pageQuery = graphql`
  query BlogPostQuery($language: String, $id: String) {
    post: mdx(id: { eq: $id }) {
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        title
        author
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
      excerpt
      id
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
